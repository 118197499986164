/* Google Tag Manager */
(function (w, d, s, l, i) {
    var gtmString = "";
    try {
      gtmString = document.getElementById("trackers-js").getAttribute("data-gtm");
    } catch (error) {}
    if (gtmString != "") {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + gtmString + dl; f.parentNode.insertBefore(j, f);
    }
})(window, document, 'script', 'dataLayer');
/* End Google Tag Manager */




function hasCookieOptOut() {
    /* OnetrustActiveGroups.includes("C0002") && */
    return !((OnetrustActiveGroups.indexOf("2") == 0, OnetrustActiveGroups.indexOf(",2") >= 0));
}

document.addEventListener("DOMContentLoaded", function() {
    window.iOneTrust = setInterval(init_cookie , 500);
});

function init_cookie()
{
    if (typeof(OnetrustActiveGroups) === "undefined" || typeof(OneTrust.OnConsentChanged) === "undefined") { return; } else { clearInterval(window.iOneTrust); }

    if (!hasCookieOptOut()) {
        document.getElementsByTagName('body')[0].classList.add("--cookie-all");
    } else {
        document.getElementsByTagName('body')[0].classList.add("--cookie-optout");
    }

    console.log(OneTrust.OnConsentChanged);
    OneTrust.OnConsentChanged(function (e) {
        console.log("OnConsentChanged");
        console.log(e.detail);
        console.log((e.detail.includes("2")));
        if (e.detail.includes("2")) {
            window.top.document.getElementsByTagName('body')[0].classList.remove("--cookie-optout");
            window.top.document.getElementsByTagName('body')[0].classList.add("--cookie-all");
        } else {
            window.top.document.getElementsByTagName('body')[0].classList.add("--cookie-optout");
            window.top.document.getElementsByTagName('body')[0].classList.remove("--cookie-all");
        }
    });

    initTock();
}

function onDecline(e) {
    document.getElementsByTagName('body')[0].classList.add("--cookie-optout");
    document.getElementsByTagName('body')[0].classList.remove("--cookie-all");
}



/* Tock Widget */
function initTock() {
    if (window.location.href.indexOf("events-chicago.html") < 0) { return; }
    
    if (!hasCookieOptOut()) {
        !function(t,o,c,k){if(!t.tock){var e=t.tock=function(){e.callMethod?    e.callMethod.apply(e,arguments):e.queue.push(arguments)};t._tock||(t._tock=e),    e.push=e,e.loaded=!0,e.version='1.0',e.queue=[];var f=o.createElement(c);f.async=!0,    f.src=k;var g=o.getElementsByTagName(c)[0];g.parentNode.insertBefore(f,g)}}(    window,document,'script','https://www.exploretock.com/tock.js');    tock('init', 'marshalls-good-stuff-social-club');

        window.iTock = setInterval(initTockButtons , 500);
    }
}

function initTockButtons() {
    if (typeof(window.tock.load) === "undefined" ) { return; } else { clearInterval(window.iTock); }
    window.tock.load('marshalls-good-stuff-social-club');
}


function OptanonWrapper() {
  if(!hasCookieOptOut()){
    document.getElementsByTagName('body')[0].classList.add("--cookie-all");
    
  }
}
